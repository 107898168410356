
import { defineComponent, ref, computed, reactive, watch } from "vue";
import ApexCharts from 'apexcharts';
import transactionApi from "@/api/transaction/";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    color: String,
  },
  setup(props) {
    const color = ref(props.color);

    const chartRef = ref<ApexCharts | null>(null);
    const chartRef2 = ref<ApexCharts | null>(null);

    const chartOptions = {
      chart: {
        type: 'bar',
        height: 1000
      },
      plotOptions: {
        bar: {
          horizontal: false,
          // columnWidth: '100%',
          // columnHeight: '200%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 5,
        colors: ['transparent']
      },
      xaxis: {
        categories: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"],
      },
      yaxis: {
        title: {
          text: '฿ (บาท)'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "฿ " + val + " บาท"
          }
        }
      },
    };

    const series1 = reactive([
      {
        name: "ยอดขาย",
        data: [],
      },
    ]);

    const series2 = reactive([
      {
        name: "บิล",
        data: [],
      },
    ]);

    const currentColor = computed(() => {
      return color.value ? color.value : "primary";
    });

    // Watch for changes in series1 to update the chart
    watch(series1, (newSeries) => {
      if (chartRef.value) {
        chartRef.value.updateSeries(newSeries);
      }
    }, { deep: true });

    return {
      currentColor,
      chartOptions,
      series1,
      chartRef,
      chartRef2
    };
  },
  data: () => ({
    todaySales: 0,
    todayBill: 0,
    chartType: "YEAR",
    types: ["YEAR", "MONTH", "DAY"],
  }),
  created() {
    this.getDashboardYearData();
  },
  methods: {
    
    async getDashboardYearData() {
      const body = {
        dashboardType: "MONTH",
      };
      const responseData = await transactionApi.transaction.getDashboard(body);
      if (responseData.response_status === "SUCCESS") {

        this.todaySales = responseData.data.todaySale.total_revenue;
        this.todayBill = responseData.data.todaySale.total_orders;

        // Update the data in series1
        this.series1[0].data = responseData.data.chartData.map((item) => item.total_revenue);
        // this.series2[0].data = responseData.data.chartData.map((item) => item.total_orders);
      }
    },
    changeType() {
      this.getDashboardYearData();
    },
  },
});
